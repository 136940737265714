body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p.file-upload-error {
    padding: 0;
    margin: 6px 0;
    font-size: .8rem;
    color: darkred;
}

.file-upload-error:first-of-type {
    padding-top: 12px;
}

div.file-upload {
    margin-top: 18px;
    font-size: 0.8rem;
}

div.file-upload input {
    margin-top: 6px;
}

div.file-upload span {
    font-size: 0.5rem;
}
div.MuiButtonBase-root.MuiExpansionPanelSummary-root.Mui-expanded {
    border-bottom: solid rgba(0, 0, 0, 0.12) 1px;
}

div.MuiExpansionPanelDetails-root>div {
    width: 100%; 
    padding-bottom: 12px;
}


.crosswalk-row {
    margin: 10px 0;
    min-height: 120px;
    padding-top: 8px;
}

.crosswalk-row-contents > div {
    position: relative;
    min-height: 96px;
}

.crosswalk-row-icon {
    align-items: center;
    display: flex;
    margin: 0 12px;
}

.crosswalk-row-item {
    width: 400px;
    max-width: 400px;
}

.crosswalk-row-item > div {
    height: 100%;
}

.crosswalk-row-item span.MuiTypography-root {
    line-height: 1rem;
    letter-spacing: 0;
    font-weight: 300;
    font-size: 0.65rem;
    display: block
}

.crosswalk-row-item div.MuiCardActions-root {
    padding-top: 0;
    padding-bottom: 0;
}

.crosswalk-row-item > div > div.MuiCardContent-root {
    padding-top: 8px;
    padding-bottom: 32px;
    width: 100%;
    box-sizing: border-box;
}

div.MuiCardContent-root {
    padding-bottom: 24px !important;
}

button.question-delete-tooltip {
    position: absolute;
    top: -4px;
    right: -4px;
}

button.expand-button {
    position: absolute;
    bottom: -4px;
    right: -4px;
    z-index: 100;
}

.response-list .response-item {
    padding: 0;
}

.response-item > div {
    margin-top: 0;
    max-width: 50%;
}

.response-list {
    padding-left: 12px;
}

.response-list > ul {
    padding: 0;
}

div.responses-container {
    padding-top: 0;
}

.add-punch {
    cursor: pointer;
    width: 50% !important; /* to prevent clicks from button */
}

.add-punch svg {
    font-size: 0.65rem;
    margin-top: -3px;
    margin-left: -2px;
    padding-right: 3px;
}

.fill-punch {
    font-size: .8rem;
    margin-top: 12px;
    padding-bottom: 25px !important;
}

.fill-punch label + .MuiInput-formControl {
    margin-top: 0;
}

.fill-punch .MuiInputLabel-marginDense {
    transform: translate(0, 5px) scale(1);
    font-size: 0.65rem;
}

.fill-punch .MuiInputLabel-shrink {
    transform: translate(0, -6px) scale(.75);
}

.fill-punch input {
    font-size: 0.8rem;
}

.fill-punch .MuiFormControl-root {
    padding-right: 8px;
}

.fill-punch svg {
    font-size: 1rem;
    cursor: pointer;
}

p.punch-error {
    margin: 0px;
    margin-top: 6px;
    font-size: .7rem;
    color: darkred;
}

.response-item .MuiSelect-select.MuiSelect-select {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 0.65rem;
}

.response-item .MuiInput-root svg {
    font-size: 20px;
    top: calc(50% - 10px);
}

.crosswalk-row-contents div.placeholder-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 12px;
}

.crosswalk {
    padding-top: 24px;
    overflow-x: auto;
}

.rows-full-width > div:first-child > div:first-child {
    padding-left: 74px;
    width: 450px;
}

.rows-full-width > div:first-child > div {
    width: 400px;
    padding-left: 24px;
}

.rows-horizontal-scroller {
    overflow-x: auto;
    width: 100%;
}

.crosswalk-row div.crosswalk-split-icon {
    align-self: center;
    padding: 0;
    width: 24px;
}

.crosswalk-split-icon button {
    padding: 0;
}

.crosswalk-row.MuiGrid-spacing-xs-2 {
    width: calc(100% + 8px);
}

.boxContainer {
    position: relative;
}

button.remove-upload-box-button {
    position: absolute;
    top: 0;
    right: 30px;
}

.file-name-container {
    margin-top: 6px;
}

.file-name-container p {
    margin: 0;
    font-size: small;
    padding-left: 6px;
    font-style: italic;
}

.ReactVirtualized__List {
    outline: none !important;
}

.build-crosswalk-panel .MuiExpansionPanelDetails-root {
    min-height: 800px;
}

.rows-full-width > div:not(.MuiGrid-root):not(.resize-triggers) {
    height: unset !important;
}

.stack-data-button-holder {
    margin: 12px 0;
}


/* Making columns have scroll bar horizontally for crosswalk */
.ReactVirtualized__Grid__innerScrollContainer {
    display: table-row;
}

.crosswalk-row.MuiGrid-spacing-xs-2, .rows-full-width > div.MuiGrid-spacing-xs-2 {
    width: max-content;
}

/* Deleted questions */
.crosswalk-deleted-container {
    padding: 10px;
    margin-top: 24px;
}

.crosswalk-deleted-card {
    width: 400px;
    position: relative;
    margin: 10px;
}


/* App Header */
header.MuiAppBar-root, .build-crosswalk-panel .MuiExpansionPanelSummary-content {
    flex-direction: row;
    justify-content: space-between;
}

button.reset-button {
    margin: 20px;
    margin-right: 24px;
}

.crosswalk-source-header-row > .MuiGrid-item {
    padding: 0 !important;
    margin: 0;
    width: 400px;
}

.button-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
}